
    import {Component, Prop, PropSync, Vue, Watch} from "vue-property-decorator";
    import {LinkedCentralSystem} from "@/domain";
    import {DataTableHeader} from "vuetify";

    @Component
    export default class CentralSystemSelect extends Vue {
        @Prop()
        readonly chargePointName!: string;

        @PropSync('values', { type: Array, default: () => [] })
        readonly valuesModel!: LinkedCentralSystem[];

        headers: DataTableHeader[] = [
            {
                text: 'Name',
                value: 'name'
            }, {
                text: 'Name',
                value: 'customChargePointName'
            }, {
                text: 'Type',
                value: 'type'
            }, {
                text: 'Commands Allowed',
                value: 'commandsAllowed',
                align: 'center'
            }, {
                text: 'Authorization Allowed',
                value: 'sessionAuthorizationAllowed',
                align: 'center'
            }, {
                text: 'Forward basic auth',
                value: 'forwardBasicAuth',
                align: 'center'
            }
        ]

        internalSelected: LinkedCentralSystem[] = [];

        @Watch('values', { deep: true })
        onValuesUpdated() {
            this.updateInternalSelected();
        }

        @Watch('internalSelected')
        onInternalSelectedUpdated(newSelected: LinkedCentralSystem[]) {
            this.valuesModel.forEach((centralSystem, index) => {
                centralSystem.selected = newSelected.findIndex(linked => linked.id == centralSystem.id) >= 0
                if (!centralSystem.selected && centralSystem.type === 'MASTER') {
                    centralSystem.type = 'READONLY'
                }
            });
        }

        mounted() {
            this.updateInternalSelected();
        }

        private updateInternalSelected() {
            this.internalSelected = this.valuesModel.filter(linked => linked.selected);
        }
    }
