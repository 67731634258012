

import {Component, Prop, PropSync, Vue} from "vue-property-decorator";
import {BasicAuthStatus, LinkedCentralSystem, NetworkProfile} from "@/domain";
import CentralSystemSelect from "@/components/CentralSystemSelect.vue";
import {BasicAuthDeviation} from "@/domain/basic-auth-deviation";

@Component({
  computed: {
    BasicAuthStatus() {
      return BasicAuthStatus
    }
  },
  components: {CentralSystemSelect},
})
export default class ChargePointForm extends Vue {

  @PropSync('name', {type: String, required: true})
  readonly nameModel!: string;

  @PropSync('version', {type: String, required: true})
  readonly versionModel!: string;

  @PropSync('type', {type: String, required: true})
  readonly typeModel!: string;

  @PropSync('basicAuthStatus', {type: String, default: () => BasicAuthStatus.NOT_REQUIRED})
  readonly basicAuthStatusModel!: BasicAuthStatus;

  @PropSync('changeBasicAuthPassword', {type: Boolean, default: () => false})
  readonly changeBasicAuthPasswordModel!: boolean;

  @PropSync('basicAuthPassword', {type: String, default: () => null})
  readonly basicAuthPasswordModel: string | null | undefined = undefined;

  @Prop()
  readonly availableBasicAuthDeviations: BasicAuthDeviation[] | undefined;

  @Prop()
  // Vue doesn't like PropSync here. Complains about this already being defined in data as well as not updating properly.
  readonly basicAuthDeviationId: number | null | undefined;

  @Prop()
  readonly availableNetworkProfiles: NetworkProfile[] | undefined;

  @Prop()
  readonly networkProfileId: number | null | undefined;

  @PropSync('url', {type: String})
  readonly urlModel!: string;

  @PropSync('useManualURI', {type: Boolean})
  readonly useManualURIModel!: boolean;

  @PropSync('useRequestHeader', {type: Boolean})
  readonly useRequestHeaderModel!: boolean;

  @PropSync('omitSoapHeader', {type: Boolean})
  readonly omitSoapHeaderModel!: boolean;

  @PropSync('selectedCentralSystems', {type: Array, default: () => []})
  readonly selectedCentralSystemsModel!: LinkedCentralSystem[];

  get selectableBasicAuthDeviations(): { text: string, value: number }[] {
    const base = [ {text: 'default', value: -1} ];
    if (!this.availableBasicAuthDeviations) {
      return base;
    }
    const copy = [... this.availableBasicAuthDeviations];
    const mapped: { text: string, value: number }[] = copy.map(deviation => ({
      text: deviation.label || '',
      value: deviation.id
    }));
    return [
      ...base,
      ...mapped,
    ];
  }

  get selectableNetworkProfiles(): { text: string, value: number }[] {
    if (!this.availableNetworkProfiles) {
      return [ {text: 'default', value: 1} ];
    }
    const profiles = [... this.availableNetworkProfiles];
    return profiles.map(profile => ({
      text: profile.name || '',
      value: profile.id
    }));
  }

  get showUrlField() {
    return this.typeModel === 'SOAP';
  }

  get basicAuthPasswordRules() {
    return [
      (v: undefined | string) => (!v || new Blob([v]).size <= 20) || 'Password must be less than or equal to 20 bytes',
    ];
  }

  get currentBasicAuthPasswordBytes(): number {
    if (!this.basicAuthPasswordModel || !this.basicAuthPasswordModel.valueOf()) {
      return 0;
    }
    return new Blob([this.basicAuthPasswordModel.valueOf()]).size;
  }

  get currentBasicAuthDeviationId(): number {
    return this.basicAuthDeviationId || -1;
  }

  get currentNetworkProfileId(): number {
    return this.networkProfileId || 1;
  }

  // Used by form
  set currentBasicAuthDeviationId(newValue: number) {
    this.$emit('update:basicAuthDeviationId', newValue);
  }

  set currentNetworkProfileId(newValue: number) {
    this.$emit('update:networkProfileId', newValue);
  }

  generateBasicAuthPassword(): void {
    const length = 20;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    let password = '';
    for (let i = 0; i < length; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    this.$emit("update:basicAuthPassword", password);
  }
}
